import Vue from 'vue'
import { config } from '@/config.settings'
import axios from 'axios'
const cookie = {
  state() {
    return {
      status: '',
      is_share: false,
      is_login: false,
    }
  },
  getters: {
    isLoggedIn: (state) => state.status === 'success' && state.is_login === true,
    isLoggedInShare: (state) => state.status === 'success' && state.is_share === true,
    isShare: (state) => state.status === 'success' && state.is_share === true,
    status: (state) => state.status,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    logout_request(state) {
      state.status = 'logouting'
      state.is_share = false
      state.is_login = false
    },
    share_api_auth_success(state, data) {
      if (data.token) {
        state.status = 'success'
        state.is_share = true
        Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + data.token
        Vue.prototype.$http.defaults.headers.common['x-page'] = window.location.pathname
        Vue.prototype.$http.defaults.baseURL = config.base_url
        Vue.prototype.$http.defaults.withCredentials = false
      }
    },
    auth_success(state) {
      state.status = 'success'
      state.is_login = true
      state.explicitly_logout = false
      Vue.prototype.$http.defaults.baseURL = config.base_url
      Vue.prototype.$http.defaults.headers.common['x-page'] = window.location.pathname
      Vue.prototype.$http.interceptors.response.use(
        function (response) {
          return response
        },
        function (error) {
          if (error && error.response && error.response.status === 403) {
            state.status = 'error'
            state.is_share = false
            state.is_login = false
            state.token = ''
            window.location.reload()
            return Promise.reject(error)
          } else {
            return Promise.reject(error)
          }
        }
      )
    },
    auth_success_share(state) {
      state.status = 'success'
      state.is_share = true
      Vue.prototype.$http.defaults.baseURL = config.base_url
      Vue.prototype.$http.defaults.headers.common['x-page'] = window.location.pathname
    },
    auth_error(state) {
      state.status = 'error'
      state.is_share = false
      state.is_login = false
      delete Vue.prototype.$http.defaults.headers.common['Authorization']
      Vue.prototype.$http.defaults.baseURL = ''
    },
    logout(state) {
      state.status = ''
      state.is_share = false
      state.is_login = false
      delete Vue.prototype.$http.defaults.headers.common['Authorization']
      Vue.prototype.$http.defaults.baseURL = ''
    },
  },
  actions: {
    customersEdit({ commit }, customers) {
      customers.forEach((c) => {
        commit('edit_customer', c)
      })
    },
    customersDelete({ commit }, id) {
      commit('del_customer', id)
    },
    customersAdd({ commit }, customer) {
      commit('add_customer', customer)
    },
    share_api_auth({ commit }, token) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.defaults.withCredentials = false
        axios({
          url: config.base_url + '/share/me',
          data: { token: token },
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('share_api_auth_success', resp.data)
              resolve(resp.data)
            } else {
              commit('auth_error')
              commit('init_user')
              reject(resp.data.message)
            }
          })
          .catch((err) => {
            commit('auth_error')
            commit('init_user')
            reject(err)
          })
      })
    },
    login({ dispatch, commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({
          url: config.base_url + '/auth',
          data: user,
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              if (resp.data.saml_redirect) {
                resolve({
                  saml: true,
                  message: resp.data.message,
                  redirect: resp.data.saml_redirect,
                })
              } else {
                commit('auth_success', resp.data)
                commit('set_user', resp.data)
                dispatch('loadGroupsUsers')
                dispatch('updateNbReviews')
                resolve(resp.data.user)
              }
            } else {
              commit('auth_error')
              commit('init_user')
              reject(resp.data.message)
            }
          })
          .catch((err) => {
            commit('auth_error')
            commit('init_user')

            reject(err)
          })
      })
    },
    login1({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({
          url: config.base_url + '/auth/preauth',
          data: user,
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              if (resp.data.saml_redirect) {
                resolve({
                  saml: true,
                  message: resp.data.message,
                  redirect: resp.data.saml_redirect,
                })
              } else if (resp.data.mfa) {
                resolve({
                  mfa: true,
                })
              } else {
                resolve({ ok: true })
              }
            } else {
              commit('auth_error')
              commit('init_user')

              reject('Internal error')
            }
          })
          .catch((err) => {
            commit('auth_error')
            commit('init_user')

            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout_request')
        axios({
          url: config.base_url + '/auth/logout',
          data: '',
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            commit('logout')
            commit('init_user')
            resolve(resp)
          })
          .catch((err) => {
            commit('auth_error')
            commit('init_user')
            reject(err)
          })
      })
    },
    setLang({ commit }, lang) {
      return new Promise((resolve, reject) => {
        if (lang === 'en_US') {
          lang = 'en'
        } else if (lang === 'fr_FR') {
          lang = 'fr'
        }
        axios({
          url: config.base_url + '/user/set_lang',
          data: { lang: lang },
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('set_lang', resp.data.lang)
              resolve(resp.data.lang)
              window.location.reload()
            } else {
              throw new Error(resp.data.message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    impersonate({ commit }, value) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.base_url + '/user/impersonate_role',
          data: { contributor: value },
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('set_impersonate_contributor', value)
              commit('set_global_admin', resp.data.is_global_admin)
              resolve(value)
              window.location = '/home'
            } else {
              throw new Error(resp.data.message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setCustomer({ commit }, customer_id) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.base_url + '/user/set_customer',
          data: { customer: customer_id },
          headers: { 'x-page': window.location.pathname },
          method: 'POST',
        })
          .then((resp) => {
            if (resp.data.ok === true) {
              commit('set_customer', resp.data.customer)
              commit('set_onboarding_enabled', resp.data.onboarding_enabled)
              commit('set_onboarding_laststep', resp.data.onboarding_laststep)
              resolve(resp.data.customer)
            } else {
              throw new Error(resp.data.message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateProfile({ commit }, data) {
      commit('set_name', data.firstname + ' ' + data.lastname)
    },
    init({ dispatch, commit, getters }) {
      commit('reset_loading')
      if (getters.isLoggedIn) {
        commit('auth_success', {})
        axios({
          url: config.base_url + '/auth/check',
          method: 'GET',
          headers: { 'x-page': window.location.pathname },
        })
          .then((resp) => {
            if (!resp.data.ok || resp.data.ok !== true) {
              commit('auth_error')
              commit('init_user')
              if (!window.location.href.includes('/login')) {
                window.location.replace('/login')
              }
            } else {
              commit('set_user', resp.data)
              dispatch('loadGroupsUsers')
              dispatch('updateNbReviews')
            }
          })
          .catch(() => {
            commit('auth_error')
            commit('init_user')
          })
      } else if (getters.isLoggedInShare) {
        commit('auth_success_share', {})
      } else {
        commit('auth_error')
        commit('init_user')
      }
    },
  },
}

export default cookie

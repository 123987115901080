import Vue from 'vue'
import store from './store'
import { CustomCache } from '@/custom_cache'
import { computeApiCacheKey } from '@/helpers/Cache.helpers'

/**
 * Use with caution : caching usage could have side effects
 * @type {CustomCache}
 */
export const api_cache = new CustomCache()

/**
 * This is a mixin used to call the API.
 * @public
 */
function apiCall(api_request, set_loading = true, default_message = '', display_toast = true) {
  if (set_loading) {
    store.commit('loading')
  }
  return new Promise((resolve, reject) => {
    api_request
      .then((res) => {
        if (res.data.ok) {
          resolve(res)
        } else if (default_message !== '') {
          if (display_toast) {
            Vue.prototype.$toast.alert(default_message)
          }
          reject(res.data.message)
        } else if (res.data.message && res.data.message !== '') {
          if (display_toast) {
            Vue.prototype.$toast.alert(res.data.message)
          }
          reject(res.data.message)
        } else {
          if (display_toast) {
            Vue.prototype.$toast.alert(Vue.prototype._('An error occured'))
          }
          reject(res.data.message)
        }
      })
      .catch((err) => {
        if (err.message !== 'canceled') {
          Vue.prototype.apiError(err, false)
          reject(err)
        }
      })
      .finally(() => {
        if (set_loading) {
          store.commit('unloading')
        }
      })
  })
}

export const apiMethod = {
  get: (api_endpoint, config = {}, set_loading = true, default_message = '', display_toast = true, cache = false) => {
    const url = `/${api_endpoint}`

    const call = () =>
      apiCall(
        Vue.prototype.$http.get(url, { ...config, signal: Vue.prototype.$httpController.signal }),
        set_loading,
        default_message,
        display_toast
      )

    const params = config !== null && typeof config !== 'undefined' ? config.params || {} : {}

    const key = computeApiCacheKey(url, params)

    return cache ? api_cache.getOrSet(key, call) : call()
  },
  getPreference: (preference, cache = false) => {
    const url = 'preference/value/' + preference

    const call = () => apiCall(Vue.prototype.$http.get(url), false, '')

    return cache ? api_cache.getOrSet(url, call) : call()
  },
  post: (api_endpoint, obj_to_post, set_loading = true, default_message = '', display_toast = true, options = {}) => {
    return apiCall(
      Vue.prototype.$http.post('/' + api_endpoint, obj_to_post, {
        ...options,
        signal: Vue.prototype.$httpController.signal,
      }),
      set_loading,
      default_message,
      display_toast
    )
  },
  put: (api_endpoint, obj_to_post, set_loading = true, default_message = '', display_toast = true) => {
    return apiCall(
      Vue.prototype.$http.put('/' + api_endpoint, obj_to_post),
      set_loading,
      default_message,
      display_toast
    )
  },
  delete: (api_endpoint, set_loading = true, default_message = '', display_toast = true) => {
    return apiCall(Vue.prototype.$http.delete('/' + api_endpoint), set_loading, default_message, display_toast)
  },
}
export default apiMethod

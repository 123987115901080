import { type CustomerFeatureFlag, FeatureFlag } from '@/contexts/shared/feature-flags/FeatureFlags.interfaces'

export const hasFeatureFlagAccess = (
  featureFlags: CustomerFeatureFlag[] | null | undefined,
  name: FeatureFlag
): boolean =>
  !!featureFlags &&
  featureFlags.length > 0 &&
  featureFlags.some(
    (featureFlag) => featureFlag.feature_flag && featureFlag.feature_flag.name === name && featureFlag.enable
  )
